import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./website/components/scrolltop/ScrollTop";
import { RoleProvider, useRole } from './RoleContext'; // Adjust the path accordingly
// Website
import Home from "./website/pages/home/Home";
import Pricing from "./website/pages/pricing/Pricing";
import Success from "./website/pages/webcreateaccount/Success";
import Failed from "./website/pages/webcreateaccount/Failed";
// Capabilities
import SetupGoogleBusinessProfile from "./website/pages/capabilities/setup-google-business-profile/SetupGoogleBusinessProfile";
import GoodReviewsLocal from "./website/pages/capabilities/good-reviews-local/GoodReviewsLocal";
import SocialMediaReviews from "./website/pages/capabilities/social-media-reviews/SocialMediaReviews";
// Verticals
import AutomativeServices from "./website/pages/verticals/automative-services/AutomativeServices";
import RealEstate from "./website/pages/verticals/real-estate/RealEstate";
import RestaurantsAndDining from "./website/pages/verticals/restaurants-and-dining/Restaurants-and-Dining";
import RetailStores from "./website/pages/verticals/retail-stores/RetailStores";
import HomeServices from "./website/pages/verticals/home-services/HomeServices";
import HealthcareProviders from "./website/pages/verticals/health-care-providers/HealthcareProviders";
import DentalFacilities from "./website/pages/verticals/dental-facilities/DentalFacilities";
import BeautyAndWellness from "./website/pages/verticals/beauty-and-wellness/BeautyAndWellness";
import LocalServices from "./website/pages/verticals/local-services/LocalServices";
import TravelAndHospitality from "./website/pages/verticals/travel-and-hospitality/TravelAndHospitality";
import LocalAttractionsAndEntertainment from "./website/pages/verticals/local-attractions-and-entertainment/LocalAttractionsAndEntertainment";
import HomeImprovement from "./website/pages/verticals/home-improvement/HomeImprovement";
import PetServices from "./website/pages/verticals/pet-services/PetServices";
import ProfessionalServices from "./website/pages/verticals/professional-services/ProfessionalServices";
import EducationAndChildCare from "./website/pages/verticals/education-and-child-care/EducationAndChildCare";
import HomeAndGarden from "./website/pages/verticals/home-and-garden/HomeAndGarden";
import GymAndFitness from "./website/pages/verticals/gym-and-fitness/GymAndFitness";
import Legal from "./website/pages/verticals/legal/Legal";
import Yoga from "./website/pages/verticals/yoga/Yoga";
// Web Login
import WebLogin from "./website/pages/weblogin/WebLogin";
import ChangePassword from "./website/pages/changepassword/ChangePassword";
import WebForgotPassword from "./website/pages/forgotpassword/WebForgotPassword";
import ConnectGoogle from "./website/pages/connectgoogle/ConnectGoogle";
import WebCreateAccount from "./website/pages/webcreateaccount/WebCreateAccount";
import AccountVerification from "./website/pages/accountverification/AccountVerification";
import Signin from "./website/pages/signin/Signin";
import Layout from "./webapp/layout/Layout";
// Admin
import AdminLogin from "./webapp/login/Login";
import CreateAccount from "./webapp/empower/accounts/create-account/CreateAccount";
import EditAccount from "./webapp/empower/accounts/edit-account/EditAccount";
import ManageAccounts from "./webapp/empower/accounts/manage-accounts/ManageAccounts";
import CreateChannel from "./webapp/empower/channel/create-channel/CreateChannel";
import EditChannel from "./webapp/empower/channel/edit-channel/EditChannel";
import ManageChannels from "./webapp/empower/channel/manage-channels/ManageChannels";
import MasterData from "./webapp/empower/masterdata/MasterData";
// Business User
import Dashboard from "./webapp/grow/dashboard/Dashboard";
import BusinessSettings from "./webapp/grow/businesssettings/BusinessSettings";
import SendInvites from "./webapp/grow/send-invites/SendInvites";
import MultipleInvites from "./webapp/grow/send-invites/multipleinvites/MultipleInvites";
import InviteSettings from "./webapp/grow/invite-settings/InviteSettings";
import Recepients from "./webapp/grow/recepients/Recepients";
import Reviews from "./webapp/grow/reviews/Reviews";
import ManageAccountChannels from "./webapp/grow/accountchannels/manageacccountchannels/ManageAccountChannels";
import CreateAccountChannel from "./webapp/grow/accountchannels/createaccountchannel/CreateAccountChannel";
import EditAccountChannel from "./webapp/grow/accountchannels/editaccountchannel/EditAccountChannel";
import Reporting from "./webapp/grow/reporting/Reporting";
import Settings from "./webapp/grow/settings/Settings";
import Support from "./webapp/grow/support/Support";
import ResetPassword from "./webapp/resetpassword/ResetPassword";
import ForgotPassword from "./webapp/forgotpassword/ForgotPassword";

function App() {
  const role = useRole()

  return (
    <RoleProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/success' element={<Success />} />
          <Route path='/failed' element={<Failed />} />
          {/* Capabilities */}
          <Route path='/capabilities/setup-google-business-profile' element={<SetupGoogleBusinessProfile />} />
          <Route path='/capabilities/good-reviews-local' element={<GoodReviewsLocal />} />
          <Route path='/capabilities/social-media-reviews' element={<SocialMediaReviews />} />
          {/* Verticals */}
          <Route path='/verticals/automative-services' element={<AutomativeServices />} />
          <Route path='/verticals/real-estate' element={<RealEstate />} />
          <Route path='/verticals/restaurants-and-dining' element={<RestaurantsAndDining />} />
          <Route path='/verticals/retail-stores' element={<RetailStores />} />
          <Route path='/verticals/home-services' element={<HomeServices />} />
          <Route path='/verticals/healthcare-providers' element={<HealthcareProviders />} />
          <Route path='/verticals/dental-facilities' element={<DentalFacilities />} />
          <Route path='/verticals/beauty-and-wellness' element={<BeautyAndWellness />} />
          <Route path='/verticals/local-services' element={<LocalServices />} />
          <Route path='/verticals/travel-and-hospitality' element={<TravelAndHospitality />} />
          <Route path='/verticals/local-attractions-and-entertainment' element={<LocalAttractionsAndEntertainment />} />
          <Route path='/verticals/home-improvement' element={<HomeImprovement />} />
          <Route path='/verticals/pet-services' element={<PetServices />} />
          <Route path='/verticals/professional-services' element={<ProfessionalServices />} />
          <Route path='/verticals/education-and-child-care' element={<EducationAndChildCare />} />
          <Route path='/verticals/home-and-garden' element={<HomeAndGarden />} />
          <Route path='/verticals/gym-and-fitness' element={<GymAndFitness />} />
          <Route path='/verticals/legal' element={<Legal />} />
          <Route path='/verticals/yoga' element={<Yoga />} />

          {/* App */}
          <Route path='/grow' element={<WebLogin />} />
          <Route path='/connect-google' element={<ConnectGoogle />} />
          <Route path='/change-password' element={<ChangePassword />} />
          <Route path='/forgot-password' element={<WebForgotPassword />} />
          <Route path='/account-registration' element={<WebCreateAccount />} />
          <Route path='/account-details' element={<Signin />} />
          <Route path='/account-verification' element={<AccountVerification />} />

          <Route path='/empower' element={<AdminLogin />} />
          <Route path='/empower/forgot-password' element={<ForgotPassword />} />
          <Route path='/empower' element={<Layout />}>
            <Route path='reset-password' element={<ResetPassword />} />
            <Route path='create-account' element={<CreateAccount />} />
            <Route path='edit-account/:correl_id' element={<EditAccount />} />
            <Route path='manage-accounts' element={<ManageAccounts />} />
            <Route path='create-channel' element={<CreateChannel />} />
            <Route path='edit-channel/:channel_id' element={<EditChannel />} />
            <Route path='manage-channels' element={<ManageChannels />} /> 
            <Route path='masterdata' element={<MasterData />} />
          </Route>

          <Route path='/grow' element={<Layout />}>
            <Route path='reset-password' element={<ResetPassword />} />

            {/* {role === '2' && <> */}
              <Route path='dashboard' element={<Dashboard />} />
              <Route path='business-settings' element={<BusinessSettings />} />
              <Route path='single-invite' element={<SendInvites />} />
              <Route path='multiple-invites' element={<MultipleInvites />} />
              <Route path='invite-settings' element={<InviteSettings />} />
              <Route path='recipients' element={<Recepients />} />
              <Route path='reviews' element={<Reviews />} />
              <Route path='manage-account-channels' element={<ManageAccountChannels />} />
              <Route path='create-account-channel' element={<CreateAccountChannel />} />
              <Route path='edit-account-channel/:account_channel_id' element={<EditAccountChannel />} />
              <Route path='reporting' element={<Reporting />} />
              <Route path='account-settings' element={<Settings />} />
              <Route path='support' element={<Support />} />
            {/* </>} */}
          </Route>
        </Routes>
      </BrowserRouter>
    </RoleProvider>
  );
}

export default App;