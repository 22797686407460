import React from 'react'
import './setupgooglebusiness.scss'
import Header from '../../../components/header/Header'
import ButtonsContainer from '../../../components/buttonscontainer/ButtonsContainer'
import { useNavigate } from 'react-router-dom'
import Footer from '../../../components/footer/Footer'

function SetupGoogleBusinessProfile() {
    const navigate = useNavigate();

    return (
        <div className='bootstrap-content google-business'>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding banner-bg'>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-7">
                            <h1 className='banner-heading text-white'>Setup Google <br /> Business Profile</h1>
                            <h3 className='mb-0 banner-sub-heading text-white'>Supercharge Your Online <br className='d-none d-lg-block' /> Presence</h3>
                        </div>
                        <div className="col-12 col-lg-5 mb-lg-0 mb-3">
                            <div className="text-center">
                                <img src={require('../../../assets/capabilites/setup-google-business-banner-image.png')} alt="" className='main-image' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-7">
                            <h3 className='list-heading'>Why Your Business Needs a Google <br className='d-none d-lg-block' /> Business Profile ?</h3>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Increased Visibility:</h4>
                                    <p className='mb-0 list-text'>Put your business on the map – literally! Your profile ensures you appear in local searches on Google Maps and relevant searches on Google.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Share Essential Information</h4>
                                    <p className='mb-0 list-text'>Easily update your contact details, hours of operation, website link, and more, all in one convenient place.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Engage with Customers</h4>
                                    <p className='mb-0 list-text'>Respond to reviews, share updates and promotions, and even message directly with customers through your profile.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="text-center">
                                <img src={require('../../../assets/capabilites/business-profile.png')} alt="" className='main-image side-image' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5">
                        <div className="col-12">
                            <div className="py-lg-4">
                                <ButtonsContainer navigate={navigate} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default SetupGoogleBusinessProfile
