import React, { useEffect, useRef, useState } from 'react'
import "./create.scss"
import moment from 'moment';
import { compressImg } from '../../../../ImageCompressor';
import Swal from 'sweetalert2';
import { createAccountApi } from '../../../../Api';
import { useNavigate } from 'react-router-dom';

const InputField = ({ disabled, value, valueFunc, label }) => {
  const inputRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (!disabled && inputRef.current) {
          inputRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <input disabled={disabled} ref={inputRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        maxLength={label === 'Mobile' && 20}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      />
    </>
  )
}

const SelectField = ({ value, valueFunc, label }) => {
  const selectRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <select ref={selectRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      >
        <option className='selectOption' value="Free">Free</option>
        <option className='selectOption' value="Start-up">Start-Up</option>
        <option className='selectOption' value="Professional">Professional</option>
        <option className='selectOption' value="Enterprise">Enterprise</option>
      </select>
    </>
  )
}
const SelectPlanType = ({ value, valueFunc, label }) => {
  const selectRef = useRef(null);
  const floatRef = useRef(null);

  return (
    <>
      <span ref={floatRef} onClick={() => {
        if (selectRef.current) {
          selectRef.current.focus();
        }
      }} className={value ? 'float' : ''}>{label}</span>
      <select ref={selectRef} type={"text"} className='w-100'
        onFocus={() => {
          if (floatRef.current) {
            floatRef.current.className = "float"
          }
        }}
        onBlur={() => {
          if (value) {
            if (floatRef.current) {
              floatRef.current.className = "float"
            }
          } else {
            if (floatRef.current) {
              floatRef.current.className = ""
            }
          }
        }}
        value={value}
        onChange={(ev) => { valueFunc(ev.target.value) }}
      >
        <option className='selectOption' value="Monthly">Monthly</option>
        <option className='selectOption' value="Yearly">Yearly</option>
      </select>
    </>
  )
}

function CreateAccount() {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = useState({
    company_name: '',
    email: '',
    first_name: '',
    last_name: '',
    mobile: '',
    website: '',
    invites: '',
    validity_start_date: '',
    validity_end_date: '',
  })
  const [validityStartDate, setValidityStartDate] = useState('');
  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const imageRef = useRef(null)
  const [plan, setPlan] = useState('Free');
  const [subscriptionType, setSubscriptionType] = useState('Monthly');

  const handleImage = (event) => {
    console.log(event);
    setImageFile(null);
    const file = event.target.files[0];
    let splitfile = file.name;
    let extension = splitfile.split('.').pop();
    if (extension === 'png' || extension === 'PNG' || extension === 'jpg' || extension === 'JPG' || extension === 'jpeg' || extension === 'JPEG') {
      console.log('valid file');
      var reader = new FileReader();
      if (event.target.files[0]) {
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (e) => {
          setImage(e.target.result);
        }
        compressImg(event.target.files[0]).then(img => {
          setImageFile(img);
        });
      }
    } else {
      Swal.fire({
        text: 'Invalid file format. Only .png, .jpg files are allowed',
        icon: 'warning',
        heightAuto: false
      })
      return
    }
  }
  const validatePhoneNumber = (number) => {
    const regex = /^\+?(\d{1,4})?(\s?[-.\s]?\(?\d{1,4}\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
    return regex.test(number);
  };
  const validateWebsite = (url) => {
    const regex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)(\S*)$/;
    return regex.test(url);
  };
  const submit = async () => {
    Swal.fire({
      html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
      customClass: { popup: 'loader' },
      showConfirmButton: false,
      heightAuto: false
    })
    let emalval = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    if (!formInfo.company_name) {
      Swal.fire({
        text: 'Company name required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }
    // else if(!formInfo.first_name){
    //   Swal.fire({
    //       text: 'First name required!',
    //       icon: 'warning',
    //       iconColor: '#000',
    //       showConfirmButton: false,
    //       heightAuto: false,
    //       timer: 1500
    //   });
    // }
    else if (!formInfo.email) {
      Swal.fire({
        text: 'Email required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!emalval.test(formInfo.email)) {
      Swal.fire({
        text: 'Please enter valid Email',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }
    else if (formInfo.mobile && !validatePhoneNumber(formInfo.mobile)) {
      Swal.fire({
        text: 'Please enter valid Mobile Number',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    }else if (formInfo.website && !validateWebsite(formInfo.website)) {
      Swal.fire({
        text: 'Please enter valid Website',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!plan) {
      Swal.fire({
        text: 'Plan required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!subscriptionType) {
      Swal.fire({
        text: 'Subscription type required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else if (!validityStartDate) {
      Swal.fire({
        text: 'Validity Start Date required!',
        icon: 'warning',
        iconColor: '#000',
        showConfirmButton: false,
        heightAuto: false,
        timer: 1500
      });
    } else {
      let formData = new FormData();
      formData.append('company_name', formInfo.company_name);
      formData.append('email', formInfo.email.toLowerCase());
      formData.append('first_name', formInfo.first_name);
      formData.append('last_name', formInfo.last_name);
      formData.append('mobile', formInfo.mobile);
      formData.append('website', formInfo.website);
      formData.append('plan', plan);
      formData.append('subscription_type', subscriptionType);
      formData.append('invites', formInfo.invites);
      formData.append('validity_start_date', validityStartDate ? moment(validityStartDate).format('YYYY-MM-DD') : '');
      formData.append('validity_end_date', formInfo.validity_end_date ? moment(formInfo.validity_end_date).format('YYYY-MM-DD') : '');
      if (imageFile) {
        formData.append('logo', imageFile)
      }
      let apiRes = await createAccountApi(formData);
      if (apiRes.status === "S") {
        Swal.fire({
          text: 'Account created successfully',
          icon: 'success',
          showConfirmButton: false,
          heightAuto: false,
          timer: 2000
        }).then(() => {
          navigate('/empower/manage-accounts')
        })
      } else {
        Swal.close()
      }
    }
  }

  useEffect(() => {
    if (validityStartDate && moment(validityStartDate).isValid()) {
      let endDate = ''
      if (subscriptionType === 'Yearly') {
        endDate = moment(validityStartDate, "MM-DD-YYYY").add(1, 'year');
        endDate = moment(endDate).format('MM-DD-YYYY');
      } else if (subscriptionType === 'Monthly') {
        endDate = moment(validityStartDate, "MM-DD-YYYY").add(1, 'month');
        endDate = moment(endDate).format('MM-DD-YYYY')
      }
      setFormInfo({ ...formInfo, validity_end_date: endDate })
    } else {
      setFormInfo({ ...formInfo, validity_end_date: '' })
    }
  }, [validityStartDate])

  useEffect(() => {
    if (plan) {
      let inviteCount = '';
      if (plan === 'Free') {
        inviteCount = 20;
      } else if (plan === 'Start-up') {
        inviteCount = 500;
      } else if (plan === 'Professional') {
        inviteCount = 1500;
      }
      if (subscriptionType === 'Yearly') {
        inviteCount = inviteCount * 12
      }
      setFormInfo({ ...formInfo, invites: inviteCount })
    } else {
      setFormInfo({ ...formInfo, invites: '' })
    }
  }, [plan])

  useEffect(() => {
    if (subscriptionType) {
      let endDate = '';
      let inviteCount = '';
      if (plan === 'Free') {
        inviteCount = 20;
      } else if (plan === 'Start-up') {
        inviteCount = 500;
      } else if (plan === 'Professional') {
        inviteCount = 1500;
      }
      if (subscriptionType === 'Yearly') {
        endDate = moment(validityStartDate, "MM-DD-YYYY").add(1, 'year');
        endDate = moment(endDate).format('MM-DD-YYYY')
        inviteCount = inviteCount * 12
      } else if (subscriptionType === 'Monthly') {
        endDate = moment(validityStartDate, "MM-DD-YYYY").add(1, 'month');
        endDate = moment(endDate).format('MM-DD-YYYY')
      }
      if (validityStartDate) {
        setFormInfo({ ...formInfo, validity_end_date: endDate, invites: inviteCount })
      } else {
        setFormInfo({ ...formInfo, validity_end_date: '', invites: inviteCount })
      }
    } else {
      setFormInfo({ ...formInfo, validity_start_date: '', validity_end_date: '' })
    }
  }, [subscriptionType]);



  return (
    <div className="bootstrap-content custom-page">
      <h5 className='heading mb-4'>Create Account</h5>
      <div className="ui-card">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="row g-4">
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.company_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, company_name: val })
                  }} label={'Company Name *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.email} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, email: val })
                  }} label={'Email *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.first_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, first_name: val })
                  }} label={'First Name'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.last_name} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, last_name: val })
                  }} label={'Last Name'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  {/* <InputField disabled={false} value={formInfo.mobile} valueFunc={(val) => {
                    setFormInfo({...formInfo, mobile: val})
                  }} label={'Mobile'} /> */}
                  <InputField
                    disabled={false}
                    value={formInfo.mobile}
                    valueFunc={(val) => {
                      // Remove any characters other than digits, special characters, hyphens, and spaces
                      const cleanValue = val.replace(/[^0-9\s()+-]/g, '') // Keep digits, spaces, hyphens, and parentheses
                      setFormInfo({ ...formInfo, mobile: cleanValue });
                    }}

                    label={'Mobile'}
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={false} value={formInfo.website} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, website: val })
                  }} label={'Website'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <SelectField value={plan} valueFunc={setPlan} label={'Select Plan *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <SelectPlanType value={subscriptionType} valueFunc={setSubscriptionType} label={'Subscription Type *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={true} value={formInfo.invites} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, invites: val })
                  }} label={'Invites'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={subscriptionType ? false : true} value={validityStartDate} valueFunc={(val) => {
                    setValidityStartDate(val)
                  }} label={'Validity start date (MM-DD-YYYY) *'} />
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="input-field">
                  <InputField disabled={true} value={formInfo.validity_end_date} valueFunc={(val) => {
                    setFormInfo({ ...formInfo, validity_end_date: val })
                  }} label={'Validity end date (MM-DD-YYYY)'} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center mt-3 mt-lg-0">
            <img src={image ? image : "https://placehold.co/200x100"} className='logo-img' alt="" />
            <input hidden ref={imageRef} type="file" onChange={handleImage} accept='.jpg, .png,. jpeg, .webp' />
            <div className='text-center mt-3'>
              <button className='btn btn-dark px-4' onClick={() => {
                if (imageRef.current) {
                  imageRef.current.click()
                }
              }}>Upload</button>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center mt-3">
              <button className='btn btn-dark px-4' onClick={() => {
                submit();
              }}>Create</button>
              <button className='btn btn-dark px-4 ms-3' onClick={() => {
                navigate('/empower/manage-accounts')
              }}>Go Back</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateAccount