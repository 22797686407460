import React from "react";
import './swiper.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function SwiperComponent() {
    var settings = {
        dots: false,
        navigation: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024, // for tablets and smaller desktops
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768, // for mobile devices
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="swiper-component">
            <Slider {
                ...settings
            }
            >
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-1.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-2.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-3.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="d-flex align-items-center">
                    <img src={require('../../assets/tripadvisoricon.png')} alt="Slide 1" className='swiper-image' />
                    <img src={require('../../assets/tripadvisor.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-5.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="d-flex align-items-center">
                    <img src={require('../../assets/carousel-images/carousel-image-6.png')} alt="Slide 1" className='swiper-image' />
                    <img src={require('../../assets/carousel-images/carousel-image-6-1.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-7.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/trustpilot.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-9.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-10.png')} alt="Slide 1" className='swiper-image' />
                </div>
                <div className="text-center">
                    <img src={require('../../assets/carousel-images/carousel-image-11.png')} alt="Slide 1" className='swiper-image' />
                </div>
            </Slider>
        </div>);
}