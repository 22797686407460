import React, { useEffect, useState } from 'react'
import "./dashboard.scss"
import { MdGroup, MdSend } from 'react-icons/md'
import { getAccountData } from '../../../Api';

function Dashboard() {
    // const data = [
    //     { label: '6-6', value: 1 }
    // ];
    // const linedata = [
    //     { value: 0 },
    //     { value: 0 },
    //     { value: 5 },
    //     { value: 0 },
    //     { value: 0 }
    // ];

    const [userData, setUserData] = useState(null)
    const [usedInvites, setUsedInvites] = useState(null)

    useEffect(() => {
        (async () => {
            let userInfo = JSON.parse(localStorage.getItem('userData') || '{}')
            setUserData(userInfo);
            let res = await getAccountData(userInfo.correl_id);
            if(res.status === 'S'){
                setUsedInvites(res.result_info.length)
            }
        })()
    }, [])

    return (
        <div className='dashboard-page'>
            <div className="dashboardContainer">
                <h5 className='dashboard-heading mb-4'>Hi {userData && userData.company_name}</h5>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="dashboard-card">
                            <h6 className="card-text">
                                {/* NEED REVIEWS */}
                                Total invites sent
                            </h6>
                            <div className="card-body p-0">
                                <MdGroup className='card-icon' />
                                <p className='mt-2 mb-0'>{usedInvites ? usedInvites : 0}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="dashboard-card">
                            <h6 className="card-text">
                                {/* TREES PLANTED */}
                                Pending invites 
                            </h6>
                            <div className="card-body p-0">
                                <MdSend className='card-tree-icon' />
                                <p className='mt-2 mb-0'>{userData && (userData.invites - (usedInvites ? usedInvites : 0))}</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-12 col-lg-4">
                        <div className="dashboard-card">
                            <h6 className="card-text">
                                AVERAGE RATING
                            </h6>
                            <div className="card-body p-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <MdStar className='card-icon' />
                                    <MdStar className='card-icon' />
                                    <MdStar className='card-icon' />
                                    <MdStar className='card-icon' />
                                    <MdStar className='card-icon' />
                                </div>
                                <p className='mt-2 mb-0'>5</p>
                            </div>
                        </div>
                    </div> */}

                </div>
                {/* <div className="row mt-lg-5">
                    <div className="col-12 col-lg-6">
                        <div className="dashboard-card">
                            <h6 className='card-text text-start px-3'>REVIEWS BY DAY</h6>
                            <Barchart data={data} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="dashboard-card">
                            <h6 className='card-text text-start px-3'>AVG. DAILY RATING</h6>
                            <Linechart linedata={linedata} />
                        </div>
                    </div>
                </div> */}
            </div>


        </div>
    )
}

export default Dashboard
