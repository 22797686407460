import React, { useState } from 'react'
import "../../css/bootstrap.scss"
import "./pricing.scss"
import Header from '../../components/header/Header'
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import { userSubscription } from '../../../Api';
import Swal from 'sweetalert2';

function Pricing() {
    const [activeTab, setActiveTab] = useState('monthly');

    const handleSubscription = async (plan, subscriptionType) => {
        Swal.fire({
            html: `<img src='/circle-1700_256.gif' style="height: 60px" alt="" />`,
            customClass: { popup: 'loader' },
            showConfirmButton: false,
            heightAuto: false
        })
        let param = subscriptionType === "Monthly" ? 'startup_monthly' : "test_yearly"
        let data = {
            plan,
            plan_type: subscriptionType,
            desiredParam: param
        }
        let apiRes = await userSubscription(data);
        if (apiRes.status === "S") {
            window.location.href = apiRes.result_info;
            Swal.close()
        }else {
            Swal.fire({
                text: 'Something went wrong, Please try again later',
                icon: 'warning',
                iconColor: '#000',
                showConfirmButton: false,
                heightAuto: false,
                timer: 2000
            })
        }
    }

    return (
        <div className='bootstrap-content pricing-page'>
            <div className="banner-sec">
                <Header />
            </div>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 mb-lg-0 mb-3">
                            <h6 className='text-center section-heading'>Plans & Pricing</h6>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <button
                                className={`tab-button ${activeTab === 'monthly' ? 'active' : ''}`}
                                onClick={() => setActiveTab('monthly')}
                            >
                                Monthly
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'yearly' ? 'active' : ''}`}
                                onClick={() => setActiveTab('yearly')}
                            >
                                Yearly ( 24% OFF )
                            </button>

                        </div>
                        <div className="tab-content">
                            {activeTab === 'monthly' && (
                                <div className="tab-pane-content">
                                    <div className="w-100">
                                        <div className="row justify-content-center">
                                            {/* <div className="col-12 col-lg-3 mb-lg-0 mb-3" >
                                                <div className="tab-card h-100">
                                                    <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'>
                                                        <div className="tab-side-banner">
                                                            <p className='mb-0'>14 days Free Trail</p>
                                                        </div>
                                                    </Link>
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user.png')} alt="" />
                                                        <p className='mb-0 start-up'>Free</p>
                                                        <p className='tabcard-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus atque</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'>
                                                            <button className="btn color-btn">
                                                                Start Free Trail
                                                            </button>
                                                        </Link>
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 0</span> /Monthly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3" >
                                                <div className="tab-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user.png')} alt="" />
                                                        <p className='mb-0 start-up'>Start-up</p>
                                                        <p className='tabcard-description'>Ideal for businesses that are just starting out or have a modest customer base.</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        {/* <Link to='/account-registration' state={{ plan: 'Start-up', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'> */}
                                                            <button className="btn color-btn" onClick={() => {handleSubscription('Start-up','Monthly')}}>
                                                                Start Free Trail
                                                            </button>
                                                        {/* </Link> */}
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 49</span> /Monthly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card second-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/rocket.png')} alt="" />
                                                        <p className='mb-0 start-up'>Professional</p>
                                                        <p className='tabcard-description'>Ideal for businesses that are just starting out or have a modest customer base.</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        {/* <Link to='/account-registration' state={{ plan: 'Professional', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'> */}
                                                            <button className="btn color-btn" onClick={() => {handleSubscription('Professional', 'Monthly')}}>
                                                                Start Free Trail
                                                            </button>
                                                        {/* </Link> */}
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 99</span> /Monthly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Monthly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user-groups.png')} alt="" />
                                                        <p className='mb-0 start-up'>Enterprise</p>
                                                        <p className='tabcard-description'>For growing companies that want to rapidly scale their review profile and outshine their competition.</p>
                                                        <button className="btn color-btn">
                                                            Contact Us
                                                        </button>
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 499</span> /Monthly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Dedicated Support Specialist</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeTab === 'yearly' && (
                                <div className="tab-pane-content">
                                    <div className="w-100">
                                        <div className="row justify-content-center">
                                            {/* <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card h-100">
                                                    <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'>
                                                        <div className="tab-side-banner">
                                                            <p className='mb-0'>14 days Free Trail</p>
                                                        </div>
                                                    </Link>
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user.png')} alt="" />
                                                        <p className='mb-0 start-up'>Free</p>
                                                        <p className='tabcard-description'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus atque</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'>
                                                            <button className="btn color-btn">
                                                                Start Free Trail
                                                            </button>
                                                        </Link>
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 0</span> /Yearly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user.png')} alt="" />
                                                        <p className='mb-0 start-up'>Start-up</p>
                                                        <p className='tabcard-description'>Ideal for businesses that are just starting out or have a modest customer base.</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        {/* <Link to='/account-registration' state={{ plan: 'Start-up', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'> */}
                                                            <button className="btn color-btn" onClick={() => {handleSubscription('Start-up','Yearly')}}>
                                                                Start Free Trail
                                                            </button>
                                                        {/* </Link> */}
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 446</span> /Yearly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card second-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/rocket.png')} alt="" />
                                                        <p className='mb-0 start-up'>Professional</p>
                                                        <p className='tabcard-description'>Ideal for businesses that are just starting out or have a modest customer base.</p>
                                                        <p className='mb-0'>&nbsp;</p>
                                                        {/* <Link to='/account-registration' state={{ plan: 'Professional', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'> */}
                                                            <button className="btn color-btn" onClick={() => {handleSubscription('Professional','Yearly')}}>
                                                                Start Free Trail
                                                            </button>
                                                        {/* </Link> */}
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 902</span> /Yearly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                                                <div className="tab-card h-100">
                                                    {/* <Link to='/account-registration' state={{ plan: 'Free', subscriptionType: 'Yearly' }} className='text-decoration-none text-black'> */}
                                                    <div className="tab-side-banner">
                                                        <p className='mb-0'>14 days Free Trail</p>
                                                    </div>
                                                    {/* </Link> */}
                                                    <div className="text-center px-lg-5">
                                                        <img src={require('../../assets/user-groups.png')} alt="" />
                                                        <p className='mb-0 start-up'>Enterprise</p>
                                                        <p className='tabcard-description'>For growing companies that want to rapidly scale their review profile and outshine their competition.</p>
                                                        <button className="btn color-btn">
                                                            Contact Us
                                                        </button>
                                                        <p className='mb-0 yearly'><span className='dollar-count'>$ 4550</span> /Yearly</p>
                                                    </div>
                                                    <div class="divider">
                                                        <img src={require('../../assets/line-1.png')} alt="" />
                                                        <span class="divider-text">Main Features</span>
                                                        <img src={require('../../assets/line-1.1.png')} alt="" />
                                                    </div>
                                                    <div className="main-content">
                                                        <ul className='list-style'>
                                                            <li className='text-center'>
                                                                <p>45 SMS credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Unlimited Email credits/mo</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Automated review collection</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Square & Zapier Integration</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Multi-step review campaigns</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Review widgets</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>All review collection tools</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Standard support</p>
                                                            </li>
                                                            <li className='text-center'>
                                                                <p>Dedicated Support Specialist</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </section>
            <section className='section2'>
                <div className="container-fluid sm-p-0">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h6 className='section2-heading'>Plans & Pricing</h6>
                            <h3 className='section2-mainheading'>Compare Plans</h3>
                            <p className='section2-desc'><strong>No contracts</strong>, just good old-fashioned support to keep you tied in.</p>
                        </div>
                    </div>
                    <div className="row justify-content-center d-none d-lg-flex">
                        <div className="col-12 col-lg-3 mb-lg-0 mb-3">
                            <div className="feature-bg-white-card h-100">
                                <h6 className='section2-card-heading '>Features /Plans</h6>

                                <h6 className='section2-card-feature-heading mb-0'>Feature 01</h6>
                                <p className='section2-card-desc '>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis. </p>

                                <h6 className='section2-card-feature-heading mb-0 '>Feature 02</h6>
                                <p className='section2-card-desc '>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis. </p>

                                <h6 className='section2-card-feature-heading mb-0 '>Feature 03</h6>
                                <p className='section2-card-desc '>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis. </p>

                                <h6 className='section2-card-feature-heading mb-0 '>Feature 04</h6>
                                <p className='section2-card-desc '>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis. </p>

                                <h6 className='section2-card-feature-heading mb-0 '>Feature 05</h6>
                                <p className='section2-card-desc '>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis. </p>
                            </div>
                        </div>
                        {/* <div className="col-12 col-lg mb-lg-0 mb-3">
                            <div className="feature-card h-100 d-flex flex-column align-items-center">
                                <h6 className='section2-card-heading text-center'>Free</h6>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-lg mb-lg-0 mb-3">
                            <div className="feature-card h-100 d-flex flex-column align-items-center">
                                <h6 className='section2-card-heading text-center'>Starter</h6>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark.png')} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg mb-lg-0 mb-3">
                            <div className="feature-card professional h-100 d-flex flex-column align-items-center">
                                <h6 className='section2-card-heading text-center'>Professional</h6>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark-white.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark-white.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark-white.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark-white.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/crossmark-white.png')} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg mb-lg-0 mb-3">
                            <div className="feature-card enterprise h-100 d-flex flex-column align-items-center">
                                <h6 className='section2-card-heading text-center'>Enterprise</h6>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                                <div className='feature-icon-container'>
                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-container d-block d-lg-none">
                        <div className="table-responsive">
                            <table className="mobile-pricing-table">
                                <thead>
                                    <tr className='table-row'>
                                        <th className='text-start'>Features /Plans</th>
                                        <th>Starter</th>
                                        <th>Professional</th>
                                        <th>Enterprise</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {[...Array(5)].map((_, index) => (
                                        <tr key={index}>
                                            <td>
                                                <h6 className='section2-card-feature-heading'>Feature 0{index + 1}</h6>
                                                <p className='section2-card-desc'>Lorem ipsum dolor sit amet consectetur. Varius ut semper lectus morbi vel vestibulum viverra tortor id. Sed faucibus semper eu tortor velit lectus quis.</p>
                                            </td>
                                            <td>
                                                <div className='feature-icon-container'>
                                                    <img src={index === 0 ? require('../../assets/checkmark.png') : require('../../assets/crossmark.png')} alt="" />
                                                </div>
                                            </td>
                                            <td className="professional">
                                                <div className='feature-icon-container'>
                                                    <img src={[0, 2].includes(index) ? require('../../assets/checkmark-white.png') : require('../../assets/crossmark-white.png')} alt="" />
                                                </div>
                                            </td>
                                            <td>
                                                <div className='feature-icon-container'>
                                                    <img src={require('../../assets/checkmark.png')} alt="" />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section3'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h6 className='section3-heading'>5x the amount of Google Reviews you collect every month</h6>
                            <p className='section3-desc'>Learn how Goodreviews’ suite of tools can help your business grow and scale today.</p>
                            <div className="text-center">
                                <button className="btn btn-black" onClick={() => { window.open('https://calendly.com/goodreviewsco', '_blank') }}>
                                    Book Demo
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div >
    )
}

export default Pricing
