import React, { useEffect, useState } from 'react'
import "./header.scss"
import { FaChevronDown, FaChevronLeft, FaChevronUp } from 'react-icons/fa';
import { BookDemo, StartFreeTrailButton } from '../buttonscontainer/ButtonsContainer';
import { useLocation, useNavigate } from 'react-router-dom';

function Header() {
    const navigate = useNavigate()
    const [isOpenCapabilities, setIsOpenCapabilities] = useState(false);
    const [isOpenVerticals, setIsOpenVerticals] = useState(false);
    const [activeSubMenu, setActiveSubMenu] = useState('');
    const [activeSubRoute, setActiveSubRoute] = useState('');

    const toggleDropdownCapabilities = (event) => {
        event.preventDefault();
        setIsOpenCapabilities(!isOpenCapabilities);
        if (isOpenVerticals) {
            setIsOpenVerticals(false); // Close the other dropdown if open
        }
    };

    const toggleDropdownVerticals = (event) => {
        event.preventDefault();
        setIsOpenVerticals(!isOpenVerticals);
        if (isOpenCapabilities) {
            setIsOpenCapabilities(false); // Close the other dropdown if open
        }
    };
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const toggleNavbar = () => {
        setIsNavbarOpen(!isNavbarOpen);
    };

    useEffect(() => {
        let pathName = window.location.pathname;
        if (pathName.includes('capabilities')) {
            setActiveSubRoute('capabilities')
        } else if (pathName.includes('verticals')) {
            setActiveSubRoute('verticals')
        }
        setActiveSubMenu(pathName)
    }, [])

    const location = useLocation();

    const isPricingPage = location.pathname === '/pricing';

    return (
        <div className='headercomponent'>
            <div className="header">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid px-0">
                        <div
                            className={`navbar-brand d-flex align-items-center pointer ${!isPricingPage ? 'home-padding' : ''}`}
                            onClick={() => navigate('/')}
                        >
                            <p className="logo-heading mb-0 me-2">Good Reviews</p>
                            <img src={require('../../assets/logo.png')} alt="Logo" className="logo" />
                        </div>
                        {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleNavbar}
                            aria-controls="navbarNavDropdown"
                            aria-expanded={isNavbarOpen ? "true" : "false"}
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" color='#000'></span>
                        </button>
                        {/* <div className="collapse navbar-collapse justify-content-lg-end align-items-lg-center" id="navbarNavDropdown"> */}
                        <div className={`collapse navbar-collapse justify-content-lg-end align-items-lg-center ${isNavbarOpen ? 'show' : ''}`} id="navbarNavDropdown">
                            <ul className="navbar-nav justify-content-end align-items-lg-center">
                                <li className="nav-item dropdown" onClick={toggleDropdownCapabilities}
                                    onMouseOver={() => {
                                        setIsOpenVerticals(false)
                                        setIsOpenCapabilities(true)
                                    }}
                                    onMouseLeave={() => {
                                        setIsOpenCapabilities(false)
                                    }}
                                >
                                    <a
                                        className={`nav-link me-lg-3 ${activeSubRoute === 'capabilities' ? 'subRoute' : ''}`}
                                        href='./'
                                        id="navbarDropdownMenuLinkCapabilities"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded={isOpenCapabilities ? "true" : "false"}
                                    >
                                        Capabilities
                                        {isOpenCapabilities ? (
                                            <FaChevronUp size={'10px'} className='ms-lg-2 ms-auto' />
                                        ) : (<>
                                            {(activeSubRoute === 'capabilities') && <FaChevronLeft size={'10px'} className='ms-lg-2 ms-auto' />}
                                            {!(activeSubRoute === 'capabilities') && <FaChevronDown size={'10px'} className='ms-lg-2 ms-auto' />}
                                        </>)}
                                    </a>
                                    <div className="link-after">
                                        <div className="curve"></div>
                                    </div>
                                    <div className={`dropdown-menu capabilities-menu ${isOpenCapabilities ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLinkCapabilities" onMouseOver={() => { setIsOpenCapabilities(true) }} onMouseLeave={() => { setIsOpenCapabilities(false) }}>
                                        <div className={`dropdown-item ${activeSubMenu === '/capabilities/setup-google-business-profile' ? 'active' : ''}`} onClick={() => { navigate('/capabilities/setup-google-business-profile') }}>Setup Google Business <br /> Profile</div>
                                        <div className={`dropdown-item ${activeSubMenu === '/capabilities/good-reviews-local' ? 'active' : ''}`} onClick={() => { navigate('/capabilities/good-reviews-local') }}>Good Reviews Local</div>
                                        <div className={`dropdown-item ${activeSubMenu === '/capabilities/social-media-reviews' ? 'active' : ''}`} onClick={() => { navigate('/capabilities/social-media-reviews') }}>Social Media Reviews</div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown" onClick={toggleDropdownVerticals}
                                    onMouseOver={() => {
                                        setIsOpenVerticals(true);
                                        setIsOpenCapabilities(false)
                                    }}
                                    onMouseLeave={() => {
                                        setIsOpenVerticals(false)
                                    }}
                                >
                                    <a
                                        // className={`nav-link ${isOpenVerticals ? 'active' : ''}`}
                                        className={`nav-link me-lg-3 ${activeSubRoute === 'verticals' ? 'subRoute' : ''}`}
                                        href='./'
                                        id="navbarDropdownMenuLinkVerticals"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded={isOpenVerticals ? "true" : "false"}
                                    >
                                        Verticals
                                        {isOpenVerticals ? (
                                            <FaChevronUp size={'10px'} className='ms-lg-2 ms-auto' />
                                        ) : (<>
                                            {(activeSubRoute === 'verticals') && <FaChevronLeft size={'10px'} className='ms-lg-2 ms-auto' />}
                                            {!(activeSubRoute === 'verticals') && <FaChevronDown size={'10px'} className='ms-lg-2 ms-auto' />}

                                        </>)}

                                    </a>
                                    <div className="link-after">
                                        <div className="curve"></div>
                                    </div>
                                    <div className={`dropdown-menu me-lg-3 ${isOpenVerticals ? 'show' : ''}`} aria-labelledby="navbarDropdownMenuLinkVerticals" onMouseOver={() => { setIsOpenVerticals(true) }} onMouseLeave={() => { setIsOpenVerticals(false) }}>
                                        <div className="row justify-content-around">
                                            <div className="col-12 col-lg-6">
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/automative-services' ? 'active' : ''}`} onClick={() => { navigate('/verticals/automative-services') }}>Automotive Services</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/beauty-and-wellness' ? 'active' : ''}`} onClick={() => { navigate('/verticals/beauty-and-wellness') }}>Beauty and Wellness</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/education-and-child-care' ? 'active' : ''}`} onClick={() => { navigate('/verticals/education-and-child-care') }}>Education and Childcare</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/gym-and-fitness' ? 'active' : ''}`} onClick={() => { navigate('/verticals/gym-and-fitness') }}>Gym and Fitness</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/healthcare-providers' ? 'active' : ''}`} onClick={() => { navigate('/verticals/healthcare-providers') }}>Healthcare Providers</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/home-and-garden' ? 'active' : ''}`} onClick={() => { navigate('/verticals/home-and-garden') }}>Home and Garden</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/home-improvement' ? 'active' : ''}`} onClick={() => { navigate('/verticals/home-improvement') }}>Home Improvement</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/home-services' ? 'active' : ''}`} onClick={() => { navigate('/verticals/home-services') }}>Home Services</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/local-attractions-and-entertainment' ? 'active' : ''}`} onClick={() => { navigate('/verticals/local-attractions-and-entertainment') }}>Local Attractions and <br className='d-none d-lg-block' /> Entertainment</div>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/local-services' ? 'active' : ''}`} onClick={() => { navigate('/verticals/local-services') }}>Local Services</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/legal' ? 'active' : ''}`} onClick={() => { navigate('/verticals/legal') }}>Legal</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/pet-services' ? 'active' : ''}`} onClick={() => { navigate('/verticals/pet-services') }}>Pet Services</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/professional-services' ? 'active' : ''}`} onClick={() => { navigate('/verticals/professional-services') }}>Professional Services</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/real-estate' ? 'active' : ''}`} onClick={() => { navigate('/verticals/real-estate') }}>Real Estate</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/restaurants-and-dining' ? 'active' : ''}`} onClick={() => { navigate('/verticals/restaurants-and-dining') }}>Restaurants and Dining</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/retail-stores' ? 'active' : ''}`} onClick={() => { navigate('/verticals/retail-stores') }}>Retail Stores</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/travel-and-hospitality' ? 'active' : ''}`} onClick={() => { navigate('/verticals/travel-and-hospitality') }}>Travel and Hospitality</div>
                                                <div className={`dropdown-item ${activeSubMenu === '/verticals/yoga' ? 'active' : ''}`} onClick={() => { navigate('/verticals/yoga') }}>Yoga Studios</div>
                                            </div>
                                        </div>

                                    </div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link pointer me-lg-3" onClick={() => { navigate('/pricing') }}>Pricing</div>
                                </li>
                                <li className="nav-item">
                                    <div className="nav-link login pointer me-lg-3" onClick={() => { navigate('/grow') }}>Log in</div>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <div className="nav-link py-0 me-3">
                                        <BookDemo />
                                    </div>
                                </li>
                                <li className="nav-item d-none d-lg-block">
                                    <div className="nav-link py-0">
                                        <StartFreeTrailButton />
                                    </div>
                                </li>
                                <div className='d-flex align-items-center text-center d-block d-lg-none'>
                                    <li className="nav-item">
                                        <BookDemo />
                                    </li>
                                    <li className="nav-item">
                                        <StartFreeTrailButton />
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

        </div>
    )
}

export default Header
