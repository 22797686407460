import React from 'react'
import './socialreviews.scss'
import Header from '../../../components/header/Header'
import ButtonsContainer from '../../../components/buttonscontainer/ButtonsContainer'
import Footer from '../../../components/footer/Footer'

function SocialMediaReviews() {
    return (
        <div className='bootstrap-content social-reviews'>
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                </div>
            </div>
            <section className='section-padding banner-bg'>
                <div className="container-fluid">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-lg-7">
                            <h1 className='banner-heading text-white'>Social Media Reviews</h1>
                            <h3 className='mb-0 banner-sub-heading text-white'>Supercharge Your Online Presence</h3>
                        </div>
                        <div className="col-12 col-lg-5 mb-lg-0 mb-3">
                            <div className="text-center text-lg-end">
                                <img src={require('../../../assets/capabilites/social-reviews-banner-image.png')} alt="" className='main-image' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='section-padding'>
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-7">
                            <h3 className='list-heading'>Why Social Media Reviews Matter to You ?</h3>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Build Trust</h4>
                                    <p className='mb-0 list-text'>Potential customers are more likely to trust what other people say about your business than what you say about yourself. Positive reviews act as powerful social proof.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Increase Visibility</h4>
                                    <p className='mb-0 list-text'>Search engines like Google factor in reviews when ranking businesses. More positive reviews can lead to higher placement in search results, helping more people find you.</p>
                                </div>
                            </div>
                            <div className='list-el'>
                                <div>
                                    <img src={require("../../../assets/logo.png")} className='list-left-image' alt="" />
                                </div>
                                <div>
                                    <h4 className='list-sub-heading'>Attract More Customers</h4>
                                    <p className='mb-0 list-text'>Reading positive experiences can be the final push someone needs to choose your business over a competitor.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="text-center text-lg-end">
                                <img src={require('../../../assets/capabilites/social-reviews.png')} alt="" className='main-image side-image' />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-lg-5">
                        <div className="col-12">
                            <div className="py-lg-4">
                                <ButtonsContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default SocialMediaReviews
