import React from 'react'
import "./buttonscontainer.scss"
import { useLocation, useNavigate } from 'react-router-dom'

export const BookDemo = () => {
  return (
    <button className='theme-button white-button' onClick={() => {
      window.open('https://calendly.com/goodreviewsco', '_blank')
    }}>
      Book Demo
    </button>
  )
}

export const StartFreeTrailButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== '/pricing') {
      navigate('/pricing');
    } else {
      navigate('/pricing');
    }
  };

  return (
    <button className='theme-button dark-button' onClick={() => { navigate('/pricing') }}>
      Start Free Trail
    </button>
  )
}

export const FreeTrailButton = () => {
  const navigate = useNavigate();

  return (
    <button className='theme-button dark-button' onClick={() => { navigate('/pricing') }}>
      14 DAYS FREE TRAIL
    </button>
  )
}
// start free trail and book demo
const ButtonsContainer = () => {
  const navigate = useNavigate();

  return (
    <div className="buttonscomponent text-center">
      <button className='dark-button me-3' onClick={() => { navigate('/pricing') }}>
        Start Free Trail
      </button>
      <button className='grey-button' onClick={() => {
        window.open('https://calendly.com/goodreviewsco', '_blank')
      }}>
        Book a Demo
      </button>
    </div>
  )
}

export default ButtonsContainer