import React, { useEffect, useRef, useState } from 'react'
import "../../css/bootstrap.scss"
import "./home.scss"
import Header from '../../components/header/Header'
import { MdOutlineStarBorderPurple500 } from 'react-icons/md'
import { FaChevronRight, FaCircleChevronRight, FaPlus } from 'react-icons/fa6'
import { AiOutlineClose } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { IoCloseCircle } from 'react-icons/io5'
import Footer from '../../components/footer/Footer'
import SwiperComponent from '../../components/swiper/SwiperComponent'
import CompanyLogos from '../../components/company-logos/CompanyLogos'

const imageUrl = [
    require('../../assets/video-image-1.png'),
    require('../../assets/video-image-2.png'),
    require('../../assets/video-image-3.png'),
    require('../../assets/video-image-4.png')
]
const VideoComponent = ({ videoUrl, imageUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    return (
        <div>
            <div className={isPlaying ? 'd-none' : "video-image-div position-relative"}>
                <img src={require('../../assets/play-button.png')} alt="" className='play-button' onClick={() => {
                    setIsPlaying(true)
                    if (videoRef.current) {
                        videoRef.current.play()
                    }
                }} />
                <img src={imageUrl} alt="" className='image-video' />
            </div>
            <div className={!isPlaying ? 'd-none' : 'video-div'}>
                <video ref={videoRef} src={videoUrl} controls onPause={() => { setIsPlaying(false) }} onEnded={() => { setIsPlaying(false) }} className='image-video'></video>
            </div>
        </div>
    )
}


function Home() {
    const [showPopup, setShowPopup] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlayClick = () => {
        setIsPlaying(true);
    };

    useEffect(() => {
        if (isPlaying && videoRef.current) {
            videoRef.current.play();
        }
    }, [isPlaying]);

    const handlePause = () => {
        setIsPlaying(false);
    };

    const handleEnded = () => {
        setIsPlaying(false);
    };
    return (
        <div className={`home-page ${showPopup ? 'hide-scroll' : ''}`}>
            {showPopup && <div className="bookpopup">
                <div className='popupCard'>
                    <div className="close">
                        <IoCloseCircle className='pointer' onClick={() => { setShowPopup(false) }} />
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <h1>Request for</h1>
                            <h2>Free Book</h2>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="quoteCard">
                                <div className='hash'>#</div>
                                <p>A good reputation is more valuable <br /> than money <span>Publius Syrus</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="row margin-top-1-5">
                        <div className="col-12 col-lg-6">
                            <div className='text-center'>
                                <img className='book' src={require('../../assets/book.png')} alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="formCard">
                                <h3 className="heading">
                                    Your Details
                                </h3>
                                <div className="input-group">
                                    <span className='label'>Name</span>
                                    <input type="text" name="Name" autoComplete='goodreviews' />
                                    <img src={require('../../assets/logo.png')} alt="" />
                                </div>
                                <div className="input-group">
                                    <span className='label'>Email</span>
                                    <input type="text" name="Email" autoComplete='goodreviews' />
                                    <img src={require('../../assets/logo.png')} alt="" />
                                </div>
                                <div className="input-group">
                                    <span className='label'>Phone Number</span>
                                    <input type="text" name="PhoneNumber" autoComplete='goodreviews' />
                                    <img src={require('../../assets/logo.png')} alt="" />
                                </div>
                                <div className="input-group">
                                    <span className='label'>Company (Optional)</span>
                                    <input type="text" name="Company" autoComplete='goodreviews' />
                                    <img src={require('../../assets/logo.png')} alt="" />
                                </div>
                                <div className="text-center">
                                    <button className="requestBtn">Request Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            <div className="header-sec">
                <div className="bg-white">
                    <Header />
                    <section className='section-padding'>
                        <div className="container-fluid">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-lg-5 mb-lg-0 mb-3">
                                    {/* <div className="bg-white-card">
                                        <img src={require('../../assets/banner-image.png')} alt="" className='banner-image' />
                                        <div className='position-absolute-img'>
                                            <img src={require('../../assets/play-button.png')} alt="" className='play-button' />
                                        </div>
                                    </div> */}
                                    <div className="bg-white-card">
                                        {!isPlaying && (
                                            <img
                                                src={require('../../assets/banner-image.png')}
                                                alt=""
                                                className='banner-image'
                                            />
                                        )}
                                        {!isPlaying && (<div className={`position-absolute-img ${isPlaying ? 'hidden' : ''}`}>
                                            <img
                                                src={require('../../assets/play-button.png')}
                                                alt=""
                                                className='play-button'
                                                onClick={handlePlayClick}
                                            />
                                        </div>)}
                                        {isPlaying && (
                                            <video
                                                ref={videoRef}
                                                className='video-player'
                                                onPause={handlePause}
                                                onEnded={handleEnded}
                                                controls
                                            >
                                                <source src="https://placehold.co/230x440.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        )}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-7 px-lg-4">
                                    <p className='banner-heading'>Empowering <br className='d-lg-block d-none' /> Business Growth <br className='d-lg-block d-none' /> with Good Reviews</p>
                                    <p className='mb-0 banner-description'>Build customer confidence by showcasing genuine positive feedback and user-created content that demonstrates your product or service's worth and excellence. Spotlighting real customers' authentic success stories can effectively boost your credibility and motivate potential buyers to make a purchase.</p>
                                    <div className="d-flex mt-4">
                                        <Link to='https://calendly.com/goodreviewsco' target='_blank'>
                                            <button className='btn btn-black me-3'>Book Demo</button>
                                            <button className='btn btn-white'>Learn More</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section className='cards-section'>
                <div className="container">
                    <div className="row justify-content-center carousel-section">
                        <SwiperComponent />
                    </div>
                    <div className="row py-lg-3">
                        <div className="col-12 justify-content-start">
                            <p className='customer-success'>CUSTOMER SUCCESS MANAGER</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-8 justify-content-start">
                            <p className='mb-0 main-heading'>We elevate you to the status of a leading provider in your industry.</p>
                            <p className='mb-0 main-description'>Distinguishing ourselves with outstanding support, we prioritize customer success by adopting a customer-centric approach and delivering top-quality products. Our commitment to excellence is evident in our investment in developers and support, surpassing our investment in sales. We provide dedicated assistance and offer clear, KPI-driven guides to ensure our customers achieve their goals.
                            </p>
                        </div>
                        <div className="col-12 col-lg-4 justify-content-end">
                            <div className='image-position'>
                                <img src={require('../../assets/person.png')} alt="" className='person-image' />
                                <div className="image-card-position d-lg-block d-none">
                                    <img src={require('../../assets/rectangle.png')} alt="" className='rectangle-image' />
                                    <div className="position-card-div">
                                        <div className="d-flex align-items-center g-0">
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        </div>
                                        <p className='position-card-content'><strong><i>... unbelievable</i></strong> customer service too, just spent an hour on chat with Dan and he got everything up and running for us in no time and fixed an error in our product catalogue!"</p>
                                        <div className='text-end d-flex justify-content-end align-items-center'>
                                            <h6 className='position-card-footer mb-0'>Food & Beverages</h6>
                                            <img src={require('../../assets/verified-account.png')} alt="" className='verified-account' />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12 text-center d-block d-lg-none">
                                    <img src={require('../../assets/rectangle.png')} alt="" className='rectangle-image' />
                                    <div className="position-card-div">
                                        <div className="d-flex align-items-center g-0">
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                            <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        </div>
                                        <p className='position-card-content'><strong><i>... unbelievable</i></strong> customer service too, just spent an hour on chat with Dan and he got everything up and running for us in no time and fixed an error in our product catalogue!"</p>
                                        <div className='text-end d-flex justify-content-end align-items-center'>
                                            <h6 className='position-card-footer mb-0'>Food & Beverages</h6>
                                            <img src={require('../../assets/verified-account.png')} alt="" className='verified-account' />
                                        </div>
                                    </div>
                                </div>
                                <div className="position-right-div">
                                    <p className='mb-0 position-right-content'>Steve Roger</p>
                                    <p className='mb-0 position-right-desc'>UK Customer Success Manager
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <section className='stars-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8">
                            <div className="stars-maincard">
                                <div className="star-images-div">
                                    <div className="d-flex align-items-center justify-conyent-center gx-3">
                                        <img src={require('../../assets/star.png')} alt="" className='star-images-star' />
                                        <img src={require('../../assets/star.png')} alt="" className='star-images-star' />
                                        <img src={require('../../assets/star.png')} alt="" className='star-images-star' />
                                        <img src={require('../../assets/star.png')} alt="" className='star-images-star' />
                                        <img src={require('../../assets/star.png')} alt="" className='star-images-star' />
                                    </div>
                                </div>
                                <div className="d-flex flex-small-column justify-content-between">
                                    <div className="position-relative">
                                        <div className="icon-div">
                                            <MdOutlineStarBorderPurple500 color='#000' fontSize={'25px'} />
                                        </div>
                                        <div className="stars-subcard">
                                            <p className='mb-2 subcard-heading'>Win 4x more reviews</p>
                                            <p className='subcard-desc mb-1'>From 20 to 130 reviews in just a few months! And our social media marketing has never looked better.                                           </p>
                                            <p className='subcard-footer mb-0'>B.Vinod</p>
                                        </div>
                                    </div>
                                    <div className="position-relative">
                                        <div className="icon-div">
                                            <MdOutlineStarBorderPurple500 color='#000' fontSize={'25px'} />
                                        </div>
                                        <div className="stars-subcard">
                                            <p className='mb-2 subcard-heading'>Get up to 2x more referrals</p>
                                            <p className='subcard-desc mb-1'>"Every single referral that our customers have sent has turned into a new customer for us." </p>
                                            <p className='subcard-footer mb-0'> Swapnil.K </p>
                                        </div>
                                    </div>
                                    <div className="position-relative">
                                        <div className="icon-div">
                                            <MdOutlineStarBorderPurple500 color='#000' fontSize={'25px'} />
                                        </div>
                                        <div className="stars-subcard">
                                            <p className='mb-2 subcard-heading'>Grow your conversion rate</p>
                                            <p className='subcard-desc mb-1'>"Converted 51% of 17,000 visitors to leads."</p>
                                            <p className='subcard-footer mb-0'>Sukumar.K</p>
                                        </div>
                                    </div>
                                    <div className="position-relative">
                                        <div className="icon-div">
                                            <MdOutlineStarBorderPurple500 color='#000' fontSize={'25px'} />
                                        </div>
                                        <div className="stars-subcard">
                                            <p className='mb-2 subcard-heading'>Gain +10% more sales</p>
                                            <p className='subcard-desc mb-1'>"Once our site went live, our call volume increased by 300-400%."                                            </p>
                                            <p className='subcard-footer mb-0'>Mahesh Babu</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className='testimonial-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 justify-content-center">
                            <h6 className='section-heading'>Our Partners</h6>
                        </div>
                    </div>
                    <CompanyLogos />
                    {/* <div className="row align-items-center">
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/aws.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/microsoft.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/google-partner.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/katalon.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/partner.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/ware.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/citrix.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/salesforce.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/cxo-magazine.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/norton.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/symantec.png')} alt="" className='partners-image' />
                        </div>
                        <div className="col-12 col-lg-3 mb-4 company-logos">
                            <img src={require('../../assets/carousel-images/carousel-image-10.png')} alt="" className='partners-image' />
                        </div>
                    </div> */}
                    <div className="row justify-content-center pt-lg-5">
                        <div className="col-12 col-lg-8 p-0">
                            <h6 className='mb-0 partners-heading'>These companies have increased their revenue & reviews,<strong>When will you?</strong></h6>
                            <p className='mb-0 partners-desc'>Watch your sales increase with the power of social proof and become the top-rated business in your local area. Sign up for your free two-week trial.</p>
                            <div className="text-center">
                                <Link to='/pricing' className='text-decoration-none text-balck'>
                                    <div className='partners-bg-black text-center'>
                                        <h6 className='mb-0'>14 Days Free Trail <FaChevronRight fontSize={'18px'} /></h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='image-video-section'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center padding-tb px-lg-0 px-3">
                            <h6 className='video-section-heading'>What business owners have to say about <strong>Good Reviews</strong></h6>
                            <div className="d-flex align-items-center justify-content-center">
                                <p className='mb-0 me-2 color-text'>See more Reviews</p>
                                <FaCircleChevronRight color='#1F32DAA6' />
                            </div>
                        </div>

                    </div>
                    <div className="row px-lg-0 px-3">
                        {imageUrl.map((url, index) => (
                            <div className="col-12 col-lg-3" key={index}>
                                <VideoComponent
                                    imageUrl={url}
                                    videoUrl={`https://placehold.co/528x1068.mp4?video=${index}`} // Replace with actual video URLs
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='good-reviews-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-12 justify-content-center">
                            <h6 className='section-heading'>Why Good Reviews Company ?</h6>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>1.9M</p>
                                    <FaPlus fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Reviews enabled</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>70,000</p>
                                    <FaPlus fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Businesses served</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>5</p>
                                    <AiOutlineClose fontSize={'20px'} color='#fff' />
                                </div>
                                <p className='good-reviews-desc mb-0'>Increase in reviews</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 mb-3 mb-lg-0">
                            <div className="good-reviews-bg-black">
                                <div className="d-flex align-items-center mb-0">
                                    <p className='mb-0 good-reviews-heading'>4.8</p>
                                    <div className="d-flex align-items-center g-0">
                                        <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                        <img src={require('../../assets/star.png')} alt="" className='position-star-image' />
                                    </div>
                                </div>
                                <p className='good-reviews-desc mb-0'>Google review rating</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='content-section'>
                <div className="row justify-content-center">
                    <div className="col-12 col-lg-8">
                        <h6 className='mb-0 content-section-heading'>Grow your business faster with Good Reviews</h6>
                        <p className='mb-0 content-section-desc'>Try Good Reviews today with a 14-day free trial and see how reputation marketing software will help you grow.No credit card required.</p>
                        <div className="text-center py-lg-4">
                            <Link to='/account-registration' className='text-decoration-none text-balck'>
                                <div className='content-bg-black text-center me-3'>
                                    <h6 className='mb-0'>Sign Up free</h6>
                                </div>
                            </Link>
                            <div className='content-bg-white text-center pointer'>
                                <h6 className='mb-0' onClick={() => { window.open('https://calendly.com/goodreviewsco', '_blank') }}>Book a Demo</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Home
