import React, { useEffect, useRef, useState } from 'react'
import "./masterdata.scss"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import { jsPDF } from 'jspdf';
import { getAccountData, getAccounts } from '../../../Api';

function MasterData() {
    const [accounts, setAccounts] = React.useState([]);
    const [selectedAccount, setSelectedAccount] = React.useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [totalPages, setTotalPages] = useState(null);
    const [content, setContent] = React.useState([]);
    const tableRef = useRef(null)

    const filteredData = [...content].filter(row =>
        Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const handleSearchChange = event => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset current page when the search query changes
    };

    const paginatedData = [...filteredData].slice((currentPage - 1) * pageSize, currentPage * pageSize);

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const previousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // const handleExportPDF = () => {
    //     const doc = new jsPDF();
    //     const table = document.querySelector('.table');
    //     const rows = Array.from(table.querySelectorAll('tr'));

    //     rows.forEach((row, rowIndex) => {
    //         const cells = Array.from(row.querySelectorAll('th, td'));
    //         cells.forEach((cell, cellIndex) => {
    //             doc.text(cell.innerText, 10 + cellIndex * 50, 10 + rowIndex * 10);
    //         });
    //     });

    //     doc.save('table-data.pdf');
    // };

    const handleExportPDF = () => {
        // Initialize jsPDF
        const doc = new jsPDF();

        // Get the table without removing columns
        const table = tableRef.current;
        const headerRow = table.querySelector('thead tr');
        const bodyRows = table.querySelectorAll('tbody tr');

        // Clone the table and remove action buttons

        // const tableClone = tableRef.current.cloneNode(true);
        // const headerRow = tableClone.querySelector('thead tr');
        // headerRow.removeChild(headerRow.lastElementChild);

        // const bodyRows = tableClone.querySelectorAll('tbody tr');
        // bodyRows.forEach(row => {
        //     row.removeChild(row.lastElementChild);
        // });

        // Extract table data
        const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
        const data = [...bodyRows].map(row =>
            [...row.querySelectorAll('td')].map(td => td.textContent)
        );

        // Extract table data, skipping the 2nd, 3rd, and 4th rows
        // const header = [...headerRow.querySelectorAll('th')].map(th => th.textContent);
        // const data = [...bodyRows].filter((_, index) => index !== 1 && index !== 2 && index !== 3).map(row => 
        //     [...row.querySelectorAll('td')].map(td => td.textContent)
        // );

        // Set up PDF document
        // This sets the font size, calculates the width of the page and margins, 
        // and determines the width of each table cell based on the number of columns. 
        // It also sets the initial vertical position (yPosition) for drawing the table.
        doc.setFontSize(10);
        const pageWidth = doc.internal.pageSize.width;
        const margin = 10;
        const usableWidth = pageWidth - 2 * margin;
        const cellWidth = usableWidth / header.length;
        const cellHeight = 10;
        let yPosition = 20;

        // Draw top border of the table
        doc.setLineWidth(0.3);
        doc.line(margin, yPosition, margin + usableWidth, yPosition);

        // Draw header
        doc.setTextColor(0);
        doc.setFont(undefined, 'bold');
        header.forEach((text, i) => {
            doc.text(text, margin + i * cellWidth + 2, yPosition + 7, { maxWidth: cellWidth - 4 });
        });
        yPosition += cellHeight;

        // Draw data rows
        doc.setFont(undefined, 'normal');
        data.forEach(row => {
            let rowHeight = cellHeight;

            // Calculate row height based on content
            row.forEach((text, i) => {
                const textLines = doc.splitTextToSize(text, cellWidth - 4);
                const cellHeight = textLines.length * 5 + 5; // 5 is approx line height
                if (cellHeight > rowHeight) rowHeight = cellHeight;
            });

            // Draw cell content
            row.forEach((text, i) => {
                doc.text(text, margin + i * cellWidth + 2, yPosition + 7, { maxWidth: cellWidth - 4 });
            });

            // Draw horizontal line
            doc.line(margin, yPosition, margin + usableWidth, yPosition);
            yPosition += rowHeight;

            // Add a new page if we're near the bottom
            if (yPosition > doc.internal.pageSize.height - 20) {
                doc.addPage();
                yPosition = 20;
            }
        });

        // Draw vertical lines
        for (let i = 0; i <= header.length; i++) {
            doc.line(margin + i * cellWidth, 20, margin + i * cellWidth, yPosition);
        }

        // Draw bottom line
        doc.line(margin, yPosition, margin + usableWidth, yPosition);

        // Save the PDF
        doc.save('table_data.pdf');
    };

    const handleExportCSV = () => {
        const table = document.querySelector('.table');
        const rows = Array.from(table.querySelectorAll('tr'));
        let csvContent = '';

        rows.forEach(row => {
            const cells = Array.from(row.querySelectorAll('th, td'));
            cells.forEach((cell, index) => {
            // cells.slice(0, -1).forEach((cell, index) => {
                csvContent += index === 0 ? `${cell.innerText}` : `,${cell.innerText}`;
            });
            csvContent += '\n';
        });

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'table-data.csv';
        link.click();
    };

    useEffect(() => {
        (async () => {
            if(selectedAccount){
                let res = await getAccountData(selectedAccount);
                if(res.status === 'S'){
                    setContent(res.result_info)
                    setTotalPages(Math.ceil(res.result_info.length / pageSize));
                }else{
                    setContent([])
                    setTotalPages(1);
                }
            }else{
                setContent([])
                setTotalPages(1);
            }
            // let res = await getMasterData();
            // if(res.status === 'S'){
            //     setContent(res.result_info)
            //     setTotalPages(Math.ceil(res.result_info.length / pageSize));
            // }
        })()
    }, [selectedAccount])

    useEffect(() => {
        (async () => {
            let accountData = await getAccounts();
            if(accountData.status === 'S'){
                accountData.result_info = accountData.result_info.filter((result) => (result.account_code !== "prod-owner"))
                setAccounts(accountData.result_info)
            }
        })()
    }, [])

    return (
        <div className='masterdata-page'>
            <div className='masterdata-container'>
                <div className='headingContainer'>
                    <h5 className='heading mb-0'>Masterdata</h5>
                </div>
                <div>
                    <select name="accountSelected" className='form-select my-3' onChange={(ev) => {setSelectedAccount(ev.target.value)}}>
                        <option value="">-- Select Company --</option>
                        {accounts && accounts.map((account) => <option key={'acc-'+account.correl_id} value={account.correl_id}>{account.company_name}</option>)}
                    </select>
                </div>
                {paginatedData && (paginatedData.length > 0) && <div className='justify-content-between d-flex mt-3'>
                    <div className='d-flex align-items-center'>
                        <button className='btn btn-white me-3' onClick={handleExportPDF}>
                            PDF
                        </button>
                        <button className='btn btn-white' onClick={handleExportCSV}>
                            EXCEL
                        </button>
                    </div>
                    <input type="text" placeholder="Search" className='searchInput' value={searchQuery} onChange={handleSearchChange} />
                </div>}
                <div className="masterdata-card ">
                    <div className="table-container">
                        <table className="table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th scope='col'>Company Name</th>
                                    <th scope='col'>First Name</th>
                                    <th scope='col'>Last Name</th>
                                    <th scope='col'>Email</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedData && (paginatedData.length > 0) && paginatedData.map((row, index) => (
                                    <tr key={'masterdata-'+index}>
                                        <td>{row.account_name}</td>
                                        <td>{row.first_name}</td>
                                        <td>{row.last_name}</td>
                                        <td>{row.email}</td>
                                    </tr>
                                ))}
                                {(!paginatedData || (paginatedData.length === 0)) && 
                                    <tr>
                                        <td>No record found</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end align-items-center mt-3">
                            <button className="btn btn-light me-2" onClick={previousPage} disabled={currentPage === 1}>
                                <MdKeyboardArrowLeft />
                            </button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button className="btn btn-light ms-2" onClick={nextPage} disabled={currentPage === totalPages}>
                                <MdKeyboardArrowRight />
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default MasterData
