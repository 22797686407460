import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
// Google Oauth Client ID
const clientId = '582292751682-i70f3kdfmkfs678vgaimj6hpd7jg28qo.apps.googleusercontent.com';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={clientId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>
);